import React, { Component, Fragment, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import SearchField from "react-search-field";

import "../../style/catalog.scss";

export class Catalog extends Component {
    static displayName = Catalog.name;

    constructor(props) {
        super(props);

        this.getDisplayedProductsDom.bind(this);
    }
    
    componentDidMount() {
        document.title = `Catalog | Soundspear - High end digital sound processing - VST - AU`;
    }

    getProductsMask(query, categoryFilter) {
        return this.props.products.map(function(p, i) {
            let visible = true;
            
            if (p.isEndOfLife) {
                return false;
            }

            if (query) {
                const searchIndex = [
                    ...new Set(
                        (p.name +
                            " " +
                            p.shortDescription +
                            " " +
                            p.compatibility +
                            " " +
                            p.homePageDescription)
                        .toLowerCase()
                        .split(/\s/)
                    )
                ];
                visible &= searchIndex.some(function(word, ii) {
                    return word.includes(query);
                });
            }
            if (categoryFilter) {
                let categoryVisible = false;
                categoryVisible |= (categoryFilter === "effect" &&
                    p.isTraditionalEffect);
                categoryVisible |= (categoryFilter === "capsule" && p.isCapsuleEffect);
                categoryVisible |= (categoryFilter === "mastering" && p.isMasteringVst);
                categoryVisible |= (categoryFilter === "bundles" && p.isBundle);
                visible &= categoryVisible;
            }

            return visible;
        });
    }

    getDisplayedProductsDom(productsMask) {
        const displayedProducts = this.props.products
            .filter((_, i) => productsMask[i]);

        const productsDom = displayedProducts.map((p, i) => {

            let priceDom;
            if (p.priceEuro === p.retailPriceEuro) {
                priceDom = <Fragment>
                    <h4>{p.priceEuro === 0 ? "Free" : `\u20AC ${p.priceEuro}`}</h4>
                </Fragment>;
            }
            else {
                priceDom = <Fragment>
                    <h5 className="retail-price"><strike>{p.priceEuro === 0 ? "" : `\u20AC ${p.retailPriceEuro}`}</strike></h5>
                    <h4>{p.priceEuro === 0 ? "Free" : `\u20AC ${p.priceEuro}`}</h4>
                </Fragment>;
            }
            return (
                <Col key={i}
                     className="catalog-item col-auto text-center">
                    <div className="catalog-product-card-top align-items-center">
                        <div className="catalog-img-wrapper">
                            <img src={p.imageUri} alt={p.name}
                                 className="img-fluid catalog-img border-soundspear-primary"
                                 onClick={() => {
                                    this.props.history.push(`/product/${p.permalink}`);
                                }}/>
                        </div>
                    </div>
                    <div className="catalog-product-card-bottom" onClick={() => {
                        this.props.history.push(`/product/${p.permalink}`);
                    }}>
                        <h3>{p.name}</h3>
                        {priceDom}
                    </div>
                </Col>
            );
        });
        return productsDom;
    }

    onNewSearch(query) {
        const productsMask = this
            .getProductsMask(query.toLowerCase(), null);

        this.setState({
            productsDom: this.getDisplayedProductsDom(productsMask)
        });
    }

    render() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);

        const productsMask = this.getProductsMask(params.get("q"), params.get("c"));

        return (
            <Container>
                <Row className="justify-content-center mt-5">
                    <SearchField
                        placeholder="Search..."
                        onSearchClick={(query) => { this.onNewSearch(query) }}
                        onEnter={(query, e) => { this.onNewSearch(query) }}/>
                </Row>
                <Row className="catalog justify-content-center">
                    {this.getDisplayedProductsDom(productsMask)}
                </Row>
            </Container>
        );
    }
}